<template>
  <div class="hk-content">
    <div class="hk-regist">
      <h4>{{ $t('login.registeredMember') }}</h4>
      <el-form label-position="left">
        <!-- <el-form-item :label="$t('login.loginId')">{{ data.name }}</el-form-item> -->
        <el-form-item :label="$t('login.companyNameCn')">{{ data.companyCn }}</el-form-item>
        <el-form-item :label="$t('login.companyNameEn')">{{ data.companyEn }}</el-form-item>
        <el-form-item
          :label="$t('account.deliveryAddress')"
        >{{ displayDistrict_delivery }}{{ data.delivery_address }}</el-form-item>
        <el-form-item
          :label="$t('login.mailingAddress')"
        >{{ displayDistrict_mail }}{{ data.mail_address }}</el-form-item>
        <el-form-item :label="$t('login.businessRegistrationNo')">{{ data.number }}</el-form-item>
        <el-form-item :label="$t('login.businessType')" class="hk-form-item_short">{{ data.type }}</el-form-item>
        <el-form-item
          :label="$t('login.companyType')"
          class="hk-form-item_short"
        >{{ data.companyType }}</el-form-item>
        <el-form-item :label="$t('login.username')">{{ data.username }}</el-form-item>
        <el-form-item :label="$t('login.contactPersonCn')">{{ data.contactsCn }}</el-form-item>
        <el-form-item :label="$t('login.contactPersonEn')">{{ data.contactsEn }}</el-form-item>
        <el-form-item :label="$t('login.phone')">{{ data.phone }}</el-form-item>

        <el-form-item :label="$t('login.receiveContactNameZh')">{{ data.receiveContactNameZh }}</el-form-item>
        <el-form-item :label="$t('login.receiveContactNameEn')">{{ data.receiveContactNameEn }}</el-form-item>
        <el-form-item :label="$t('login.receiveContactMobile')">{{ data.receiveContactMobile }}</el-form-item>
        <ul class="hk-upload">
          <li>
            <label>{{ $t('login.BRCopy') }}</label>
            <div class="hk-upload__image">
              <span class="upload_preview">
                <a :href="brPath" v-if="brPath" target="_blank">{{ $t('account.view') }}</a>
              </span>
            </div>
          </li>
          <li>
            <label>{{ $t('login.CICopy') }}</label>
            <div class="hk-upload__image">
              <span class="upload_preview">
                <a :href="ciPath" v-if="ciPath" target="_blank">{{ $t('account.view') }}</a>
              </span>
            </div>
          </li>
        </ul>
        <div class="hk-agreement">
          <div>
            <el-button type="primary" @click="goEdit">{{ $t('common.prev') }}</el-button>
            <el-button
              type="primary"
              :disabled="disableButton"
              @click="submit"
            >{{ $t('login.submit') }}</el-button>
          </div>
        </div>
      </el-form>
      <hk-preview ref="preview" :data="previewData" />
    </div>

    <el-dialog :visible.sync="showWaitDialog">
      <i class="iconfont icon-time" />
      <p>{{ $t('login.wait') }}......</p>
      <p>{{ $t('login.fail.2', ['63126760']) }}</p>
    </el-dialog>

    <el-dialog :visible.sync="showFailDialog">
      <i class="iconfont icon-fail" />
      <p>{{ $t('login.fail.1') }}</p>
      <p>{{ $t('login.fail.2', ['63126760']) }}</p>
    </el-dialog>

    <el-dialog :visible.sync="showBRDialog">
      <i class="iconfont icon-fail" />
      <p>{{ alert }}</p>
    </el-dialog>

    <el-dialog
      class="hk-success-dialog"
      :visible.sync="showSuccessDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <i class="iconfont icon-success" />
      <div>
        <h4>{{ $t('login.successfulSubmitted') }}</h4>
      </div>
      <p>{{ $t('login.success.1') }}</p>
      <p>{{ $t('login.success.2') }}</p>
      <p>{{ $t('login.success.3', ['63126760']) }}</p>
      <a @click="goHome">{{ $t('common.back') }}</a>
    </el-dialog>
  </div>
</template>

<script>
import HkPreview from '@/components/preview'
export default {
  name: 'HkRegistPreview',
  components: {
    HkPreview,
  },
  data() {
    let self = this
    let previewData = this.$store.state.data.user
    let brImagePath = this.$store.state.data.user.brImagePath
    let ciImagePath = this.$store.state.data.user.ciImagePath
    self.getArea()
    return {
      data: previewData,
      brPath: brImagePath,
      ciPath: ciImagePath,
      previewData: [],
      agree: true,
      showSuccessDialog: false,
      showFailDialog: false,
      showWaitDialog: false,
      showBRDialog: false,
      alert: '',
      token: localStorage.getItem('SSID'),
      mailAddressOptions: [],
      displayDistrict_delivery: '',
      displayDistrict_mail: '',
      disableButton: false,
    }
  },
  methods: {
    submit() {
      let self = this
      self.disableButton = true
      let formData = new FormData()
      formData = {
        // merchants_name: this.data.name,
        merchants_number: this.data.number,
        company_name_zh: this.data.companyCn,
        company_name_en: this.data.companyEn,
        industry_type: this.data.type,
        company_type: this.data.companyType,
        username: this.data.username,
        contacts_zh: this.data.contactsCn,
        contacts_en: this.data.contactsEn,
        mobile: this.data.phone,
        recive_contact_name_zh: this.data.receiveContactNameZh,
        recive_contact_name_en: this.data.receiveContactNameEn,
        recive_contact_mobile: this.data.receiveContactMobile,
        copy_of_business_registration: this.data.brImagePath,
        copy_of_company_certificate: this.data.ciImagePath,
        delivery_address: this.data.delivery_address,
        mail_address: this.data.mail_address,
        delivery_address_id: this.data.delivery_address_id,
        mail_address_id: this.data.mail_address_id,
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi + '/api/user/approval?lang=' + self.$store.state.config.language
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: self.token,
          },
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showSuccessDialog = true
              self.disableButton = false
              break
            case 2006:
              self.showWaitDialog = true
              self.disableButton = false
              break
            case 2028:
              self.alert = response.data.msg
              self.showBRDialog = true
              self.disableButton = false
              break
            default:
              self.showFailDialog = true
              self.disableButton = false
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      //
    },
    goEdit() {
      let self = this
      //this.$store.commit('register', self.data);
      this.$router.push({
        name: 'regist',
      })
    },
    goHome() {
      this.$router.replace({
        name: 'home',
      })
    },
    previewShow(src) {
      const el = this.$refs.preview
      this.previewData = [src]
      this.$nextTick(() => {
        el && el.show(0)
      })
    },
    getArea() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/areas?lang='
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.mailAddressOptions = response.data.data
          console.log(self.data)
          self.displayDistrict_delivery = self.mailAddressOptions.filter(
            (item) => item.id == self.data.delivery_address_id
          )[0].name
          self.displayDistrict_mail = self.mailAddressOptions.filter(
            (item) => item.id == self.data.mail_address_id
          )[0].name
          console.log(self.displayDistrict)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/preview';
</style>
<style>
.el-button:disabled {
  background-color: gray !important;
}
</style>
